export function copyTextToClipboard(text) {
  return navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : fallbackCopyTextToClipboard(text);
}

export function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, reject) => {
    try {
      document.execCommand("copy");
      resolve();
    } catch (err) {
      console.error(err);
      reject("Fallback: Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  });
}