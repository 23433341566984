import config from "../config";
import {hit, hitOnce, hits} from "../tools/hits";
import {logEvent, logEventOnce, userEvents} from "../tools/log";
import {copyTextToClipboard} from "../tools/text";
import {gaEvents, gaSendEvent} from "../tools/ga";

const loadedTime = new Date().toLocaleTimeString().replace(/(\d+:\d{2})(:\d{2})(.*)/, "$1$3");

window.toonAppData = {};

if (!localStorage["tooncoin:howtobuy_variant"]) {
  localStorage["tooncoin:howtobuy_variant"] = (Math.random() > 0.5 ? "bot" : "page");
}

const howToBuyVariant = config.query["howtobuy_variant"] || localStorage["tooncoin:howtobuy_variant"];

setInterval(function() {
  const firstVisitAt = parseInt(localStorage["tooncoin:first_visit_at"]);
  const now = Date.now();

  if (now >= firstVisitAt + 10000) {
    logEventOnce("visit_10s");
  }

  if (now >= firstVisitAt + 15000) {
    logEventOnce("visit_15s");
  }
}, 1000);

$(document).ready(function() {
  if (howToBuyVariant === "bot" && !window.ethereum) {
    $(".js-howtobuy-link").attr("href", "https://t.me/ToonInfoBot");
    $(".js-howtobuy-link").attr("target", "_blank");
  }

  $(".js-place-time").text(loadedTime);

  if (config.query["utm_source"] && /\-mm$/.test(config.query["utm_source"])) {
    hit(hits.continueByMetaMask);
  }

  document.addEventListener("beforeunload", function() {
    logEvent(userEvents.pageUnload);
  });

  fetch("./price.json").then(function(res) {
    return res.json();
  }).then(function(res) {
    window.toonAppData = res;
    $(".js-contract-address").text(res.address);
    $(".js-token-price-text").text("$" + parseFloat(res.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
    $(".js-eth-price").text("$" + parseFloat(res.ethRate).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
  });

  hitOnce(hits.visit);

  if (window.ethereum) {
    hitOnce(hits.visitWithMetamask);
  } else {
    if (window.outerWidth === window.screen.availWidth && window.outerWidth < 992 && config.userSplitGroup === 1) {
      // $("#dapp-deeplink").addClass("shown");
      // hit(8967);
    }
  }

  $(".dapp-deeplink-button").on("click", function() {
    hit(hits.continueByMetaMaskClick);

    if (config.query["utm_source"]) {
      config.query["utm_source"] += "-mm";
    } else {
      config.query["utm_source"] = "direct-mm";
    }

    const goToUrl = "https://metamask.app.link/dapp/"
      + window.location.href.replace(/^https?:\/\//i, "");

    setTimeout(() => window.location.href = goToUrl, 200);
  });

  // smooth scrolling
  if ($(".index-page").length > 0) {
    $(".header__menu-item:not(:last-of-type) a, .banner__button").on("click", function (event) {
      event.preventDefault();
      const id = $(this).attr('href');
      const top = $(id).offset().top;

      $("body, html").animate({scrollTop: top - 100}, 600);
    });
  }

  // mobile menu
  $('.btn-menu').click(function () {
    $('.header').toggleClass('active scrolled')
    $('.header__shadow').toggleClass('hidden')
    $('.btn-menu').toggleClass('active')
    $('.main').toggleClass('blur')
    $('body').toggleClass('noscroll')
    $('.header__menu-item a').click(function () {
      $('.header').removeClass('active')
      $('.header__shadow').addClass('hidden')
      $('.btn-menu').removeClass('active')
      $('.main').removeClass('blur')
      $('body').removeClass('noscroll')
    })
  })

  // fixed menu
  const menu = $('.main');
  let menuPosition;

  if ($(document).scrollTop() > 0) {
    $('.header').addClass('scrolled');
  }

  $(window).scroll(function () {
    const menuCoords = menu.offset();
    const windowScroll = $(this).scrollTop();

    if (!menuPosition) {
      menuPosition = menuCoords.top;
    }

    if (windowScroll > 0) {
      $('.header').addClass('scrolled')
    } else {
      menuPosition = false;
      $('.header').removeClass('scrolled')
    }
  });

  logEvent("page_" + document.body.dataset.page);

  $(".js-bot-link").on("click", function(e) {
    logEvent(userEvents.gotoBotClick, {href: $(e.currentTarget).attr("href")});
  });

  const howBuyBtn = document.querySelector('#howBuyButton');
  if (howBuyBtn) {
    howBuyBtn.addEventListener("click", function(e) {
      gaSendEvent(gaEvents.cryptoSelectButton);
      logEvent(userEvents.cryptoSelectButton);

      this.classList.toggle("active");
      document.querySelector('.how__crypto').classList.toggle("active");

      if (window.innerWidth <= 900) {
        document.body.classList.add("noscroll");
        document.querySelector('.how__crypto-close').addEventListener("click", () => {
          document.body.classList.remove("noscroll");
          howBuyBtn.classList.remove("active");
          document.querySelector('.how__crypto').classList.remove("active");
        })
      }
    });
  }

  const howCryptoForm = document.querySelector('.how__crypto-form');
  const howCryptoInput = document.querySelector('.how__crypto-input');

  if (howCryptoForm) {
    howCryptoForm.addEventListener("submit", function(e) {
      e.preventDefault();

      if (howCryptoInput.value.length > 0) {
        cryptoFormFunc();
      }
    });
  }

  function cryptoFormFunc() {
    logEvent(userEvents.cryptoSelect, {
      is_custom: true,
      item: howCryptoInput.value,
    });

    gaSendEvent(gaEvents.cryptoSelect, {
      item: howCryptoInput.value,
    });

    howCryptoForm.classList.remove("active");
    howBuyBtn.innerHTML = '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="a" style="mask-type:alpha" x="0" y="0" width="48" height="48"><path fill="#D9D9D9" d="M0 0h48v48H0z"/></mask><g mask="url(#a)"><path d="M25 44C13.972 44 5 35.028 5 24S13.972 4 25 4a20.08 20.08 0 0 1 17.32 9.994.743.743 0 0 1-.27 1.013l-3.51 2.028a.75.75 0 0 1-1.022-.287C34.51 11.561 28.495 8.664 22.43 9.76c-6.922 1.258-12.032 7.491-11.895 14.526.153 7.846 6.583 14.182 14.465 14.182a14.509 14.509 0 0 0 12.14-6.6l-2.248-1.3A11.912 11.912 0 0 1 25 35.877c-6.549 0-11.877-5.328-11.877-11.877S18.451 12.13 25 12.13a11.92 11.92 0 0 1 9.854 5.248.747.747 0 0 1-.77 1.154.733.733 0 0 1-.445-.3A10.438 10.438 0 0 0 25 13.612c-5.865 0-10.61 4.88-10.386 10.79.165 4.374 3.142 8.248 7.326 9.532A10.4 10.4 0 0 0 34 29.2a.742.742 0 0 1 1.013-.271l3.528 2.04a.736.736 0 0 1 .28.997A15.992 15.992 0 0 1 25 39.953C16.206 39.95 9.05 32.795 9.05 24c0-8.795 7.156-15.95 15.95-15.95a16.006 16.006 0 0 1 13.425 7.339l2.228-1.287A18.6 18.6 0 0 0 25 5.482C14.79 5.482 6.482 13.79 6.482 24S14.79 42.518 25 42.518a18.596 18.596 0 0 0 15.662-8.634.744.744 0 0 1 1.352.201.742.742 0 0 1-.086.57A20.084 20.084 0 0 1 25 44z" fill="#FFD06A"/></g></svg>' + document.querySelector('.how__crypto-input').value;
    howBuyBtn.classList.add("selected");
    document.querySelector('.how__crypto').classList.remove("active");
    document.querySelector('.how__steps').classList.add("active");
    document.querySelector('.how__steps-name').textContent = howCryptoInput.value;

    if (window.innerWidth <= 900) {
      document.body.classList.remove("noscroll");
    }

    setTimeout(function () {
      howCryptoInput.value = '';
      howCryptoForm.classList.remove("send");
    },100);
  }

  if (howCryptoInput) {
    howCryptoInput.addEventListener("input", (event) => {
      if (event.currentTarget.value.length > 0) {
        howCryptoForm.classList.add("send");
      } else {
        howCryptoForm.classList.remove("send");
      }
    });
  }

  const howCryptoItem = document.querySelectorAll('.how__crypto-item');
  for (let i = 0; i < howCryptoItem.length; i++) {
    howCryptoItem[i].addEventListener("click", (event) => {
      if (window.innerWidth <= 900) {
        document.body.classList.remove("noscroll");
      }

      if (howCryptoItem[i].classList.contains("fav-crypto")) {
        howCryptoForm.classList.add("active");
        if (window.innerWidth <= 900) {
          howCryptoForm.scrollIntoView({block: "start", behavior: "smooth"});
        }
      } else {
        const cryptoName = howCryptoItem[i].querySelector("span").innerText.trim();

        logEvent(userEvents.cryptoSelect, {
          is_custom: false,
          item: cryptoName,
        });

        gaSendEvent(gaEvents.cryptoSelect, {
          item: cryptoName,
        });

        howBuyBtn.classList.add("selected");
        howBuyBtn.innerHTML = howCryptoItem[i].innerHTML;
        document.querySelector(".how__crypto").classList.remove("active");
        document.querySelector(".how__steps").classList.add("active");
        document.querySelector(".how__steps-name").innerHTML = howCryptoItem[i].innerHTML;
      }
    });
  }

  const indexSlider = document.querySelector('.slider__container');
  if (indexSlider) {
    new Swiper(".swiper", {
      direction: "horizontal",
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      speed: 400,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 10000,
        disableOnInteraction: false
      }
    });
  }

  const reachedPlaces = [];
  setInterval(() => {
    const scrollTop = $(window).scrollTop();
    const windowHeight = $(window).height();

    $("[data-scroll-place-id]").each((_, item) => {
      const placeId = $(item).data("scroll-place-id");
      const placeTop = $(item).offset().top;

      if (placeTop < scrollTop + windowHeight/2) {
        if (reachedPlaces.indexOf(placeId) === -1) {
          reachedPlaces.push(placeId);
          gaSendEvent(gaEvents.scrollPlace, {place: placeId});
          logEvent(userEvents.scrollPlace, {place: placeId});
        }
      }
    });
  }, 100);
});

const copyBtn = document.querySelector(".copy-btn");
copyBtn && copyBtn.addEventListener("click", function(e) {
  e.preventDefault();
  copyTextToClipboard(window.toonAppData.address).then(() => {
    hit(hits.contractAddressCopied);
  });

  logEvent(userEvents.copyAddress);
});

const mainBlock = document.querySelector(".main");
mainBlock && mainBlock.addEventListener("mouseup", function() {
  if (getSelectedText().indexOf(window.toonAppData.address) >= 0) {
    hitOnce(hits.contractAddressSelection);
  }
});

function getSelectedText() {
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection.type === "Range") {
      return selection.toString();
    }
  } else if (document.selection) {
    return document.selection.createRange().text;
  }

  return "";
}
